@import "../scss/variables";

@media (min-width: 1025px) {
	.boxed {
		.page {
			width: 1400px;
			margin: 0 auto;
			background: #f2f6f9;
			position: relative;
			display: -ms-flexbox;
			display: -webkit-box;
			display: flex;
			-ms-flex-direction: column;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			flex-direction: column;
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
			min-height: 100vh;
			-webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 5px 0 0, rgba(0, 0, 0, 0.3) 0 5px 25px 0;
			box-shadow: rgba(0, 0, 0, 0.3) 0 5px 0 0, rgba(0, 0, 0, 0.3) 0 5px 25px 0;
		}

		.header.top-header {
			width: 1400px !important;
		}

		.hor-menu.horizontal-main {
			max-width: 1400px;
		}

		.main-content {
			.container,
			.container-fluid {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.main-header > {
			.container,
			.container-fluid {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.app-sidebar {
			left: auto !important;
		}

		.side-header {
			left: auto !important;
			right: auto !important;
			z-index: 1044;
		}

		.main-header.side-header {
			max-width: 1400px;
		}

		.nav-tabs .nav-link {
			padding: 1rem 1.2rem;
		}

		&.app.sidenav-toggled .app-sidebar.comb-sidebar {
			left: -230px !important;
		}

		.horizontalMenu > .horizontalMenu-list > li:last-child > ul.sub-menu {
			right: initial;
		}
		.horizontalMenu > .horizontalMenu-list > li > a {
			padding: 7px;
		}

		.page-style1 .page-content {
			z-index: 999;
		}

		.app-header {
			padding-right: 10px !important;
		}

		.second-sidemenu {
			left: auto !important;
			margin-left: 70px;
		}

		&.app.sidebar-mini.sidenav-toggled4 .first-sidemenu {
			left: auto !important;
		}

		.profile-dropdown {
			padding-right: 0;
		}
		@media (min-width: 992px){
			.landingmain-header.header .horizontal-main {
				width: 1400px;
			}
			
			.landingmain-header.header .horizontalMenu > .horizontalMenu-list > li > a {
			padding: .75rem 1.2rem ;
		 }
		}
	}

	body.boxed {
		background: #bdc0c8 !important;
	}

	.boxed .app-header.header {
		width: 1400px;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 1162px) and (min-width: 992px) {
	.boxed .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
		font-size: 12px;
		padding: 10px 5px 10px 5px !important;
	}
}
.boxed.rtl {
	.app-content {
	 margin-right: 0px; 
	  margin-left: inherit;
	}
	.app-sidebar{
		left:auto !important;
	}
	.app-content .side-app {
		padding: 25px  m 0 1.5rem;
	}
	@media (min-width: 768px){
		.app-content {
			margin-right: 270px;
			padding-bottom: 0rem;
		}
		&.sidenav-toggled .app-header {
			padding-right: 70px !important;
		}
		
	}
	@media (min-width: 1025px){
	.app-header {
			padding-left: 10px!important;
			padding-right: 270px !important;
		}
		.app-sidebar{
			right:auto !important;
		}
	}
	&.closed.sidenav-toggled .app-header {
		padding-right: 0px !important;
	}
	&.hover-submenu.sidenav-toggled .app-header {
		padding-right: 90px!important;
	}
	&.hover-submenu1.sidenav-toggled .app-header {
		padding-right: 90px!important;
	}
	&.icon-text.sidenav-toggled .app-header {
		padding-right: 90px!important;
	}

}
.today-profit {
	margin-left: 30px;
}

.boxed.dark-mode .page {
	background:#151636;
	-webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
}
body.boxed.dark-mode{
	background: #1c1d32 !important;
	} 
